// @flow
import { get as getSession } from "@utils/session";

const searchEngines = [
    "google", //yes
    "bing", //yes
    "yahoo", //yes
    "ecosia", //yes
    "duckduckgo", //yes
    "qwant", //yes
];

const socialMediaPlatforms = [
    "linkedin",
    "facebook",
    "youtube",
    "t.co",
    "instagram",
    "lnkd.in",
];

export const getUTMParameters = () => {
    const sessionData = getSession();
    const urlParameters =
        sessionData.entry && hasUTMParameters(sessionData.entry.search)
            ? sessionData.entry.search
            : sessionData.current &&
              hasUTMParameters(sessionData.current.search)
            ? sessionData.current.search
            : {};

    return urlParameters;
};

export const hasUTMParameters = (utmParams: Object) => {
    const hasParams = Object.values(utmParams).some(param => param !== "");
    return hasParams;
};

export const isOrganicSearchEngineReferral = (referrer: string) =>
    searchEngines.some(engine => referrer.includes(engine)) ? "organic" : null;

export const isSocialMediaReferral = (referrer: string) =>
    socialMediaPlatforms.some(platform => referrer.includes(platform))
        ? "referral_social-media"
        : null;

export const getMedium = (referrer: string) =>
    referrer === ""
        ? "direct"
        : isOrganicSearchEngineReferral(referrer) ??
          isSocialMediaReferral(referrer) ??
          "referral_external";

export const getReferrerKeys = (session: *) => {
    const referrer = hasUTMParameters(getUTMParameters())
        ? getUTMParameters()
        : {
              utm_medium: session?.referrer?.parsed ?? "direct",
              utm_source: session?.referrer?.raw ?? "",
          };
    return referrer;
};
